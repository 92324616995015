import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './guards/auth.guard';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { EmpresaComponent } from './layout/empresa/empresa.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              loadChildren: () =>
                import('./layout/dashboard/dashboard.module').then(
                  (m) => m.DashboardModule
                ),
            },
            {
              path: 'procesos',
              loadChildren: () =>
                import('./layout/procesos/procesos.module').then(
                  (m) => m.ProcesosModule
                ),
            },
            {
              path: 'operaciones-internas',
              loadChildren: () =>
                import(
                  './layout/operaciones-internas/operaciones-internas.module'
                ).then((m) => m.OperacionesInternasModule),
            },
            {
              path: 'almacenes',
              loadChildren: () =>
                import('./layout/almacenes/almacenes.module').then(
                  (m) => m.AlmacenesModule
                ),
            },
            {
              path: 'personas',
              loadChildren: () =>
                import('./layout/personas/personas.module').then(
                  (m) => m.PersonasModule
                ),
            },
            {
              path: 'potenciales-clientes',
              loadChildren: () =>
                import('./layout/potenciales-clientes/potenciales-clientes.module').then(
                  (m) => m.PotencialesClientesModule
                ),
            },
            {
              path: 'acciones-comerciales',
              loadChildren: () =>
                import('./layout/acciones-comerciales/acciones-comerciales.module').then(
                  (m) => m.AccionesComercialesModule
                ),
            },
            {
              path: 'tipos-acciones-comerciales',
              loadChildren: () =>
                import('./layout/tipos-acciones-comerciales/tipos-acciones-comerciales.module').then(
                  (m) => m.TiposAccionesComercialesModule
                ),
            },
            {
              path: 'acciones',
              loadChildren: () =>
                import('./layout/acciones/acciones.module').then(
                  (m) => m.AccionesModule
                ),
            },
            {
              path: 'empresa',
              component: EmpresaComponent,
            },
          ],
        },
        {
          path: 'auth',
          loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
        },
        // { path: 'notfound', component: NotfoundComponent },
        { path: '**', component: NotfoundComponent },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
