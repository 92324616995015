import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  Empresa,
} from 'src/app/interfaces/empresa';
import { MercanciaService } from 'src/app/services/mercancia.service';
import { CustomMessageService } from 'src/app/services/message.service';
import { EmpresaService } from 'src/app/services/empresa.service';

import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
  UploadResponse,
} from 'ngx-image-compress';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss'],
})
export class EmpresaComponent implements OnInit, OnDestroy {
  // servicios requeridos
  private readonly empresaService = inject(EmpresaService);
  private readonly activatedRoutes = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly messageService = inject(CustomMessageService);
  private readonly Form = inject(FormBuilder);
  private readonly imageCompress = inject(NgxImageCompressService);

  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  imgResultAfterResize: DataUrl = '';
  imgResultUpload: DataUrl = '';
  imgResultAfterResizeMax: DataUrl = '';
  imgResultMultiple: UploadResponse[] = [];

  public compuestoSnOptions: Object[] = [
    { value: 'Si', label: 'Si' },
    { value: 'No', label: 'No' },
  ];

  public tiposEmpresas: string[] = [];

  public imagenFile: File[] = [];

  public hadSubmit: boolean = false;

  public empresaAEditar: Empresa;

  public imagenSubida: any;

  public cantidadAPoner: number | null = null;

  public loading = true;

  public loadingTipos = true;

  public subs: Subscription[] = [];

  public selectSiNo: string[] = ['Si', 'No'];

  public newEmpresa = this.Form.group({
    nombre: [null, Validators.required],
    NIF: [null, Validators.required],
    direccion: [null, Validators.required],
    telefono: [null, Validators.required],
    correo: [null, Validators.required],
    piePagina: [null, Validators.required],
    imagenPdf: [null, Validators.required],
  });

  // form getters ----------------------------------------------------------------------------------

  get nombre() {
    return this.newEmpresa.get('nombre') as UntypedFormControl;
  }
  get NIF() {
    return this.newEmpresa.get('NIF') as UntypedFormControl;
  }
  get direccion() {
    return this.newEmpresa.get('direccion') as UntypedFormControl;
  }
  get telefono() {
    return this.newEmpresa.get('telefono') as UntypedFormControl;
  }
  get correo() {
    return this.newEmpresa.get('correo') as UntypedFormControl;
  }
  get piePagina() {
    return this.newEmpresa.get('piePagina') as UntypedFormControl;
  }
  get imagenPdf() {
    return this.newEmpresa.get('imagenPdf') as UntypedFormControl;
  }

  // end form getter --------------------------------------------------------------------------------

  ngOnInit(): void {
    this.cargarEmpresaAEditar();
  }

  back() {
    let lastUrl = sessionStorage.getItem('lastUrl');
    this.router.navigateByUrl(lastUrl);
  }

  validar(campo: string) {
    return this.newEmpresa.get(campo)?.valid;
  }

  esEntero(num: number) {
    if (num === null) return true;
    if (num === 0) return true;
    if (Number.isInteger(num)) {
      return true;
    } else return false;
  }

  submit() {
    this.hadSubmit = true;

    if (this.newEmpresa.invalid) {
      console.log(this.newEmpresa.invalid);
      this.messageService.error('Verifique los campos');
      return;
    }

    let empresaToSend: Empresa = this.newEmpresa.value as Empresa;

    empresaToSend.id = this.empresaAEditar.id;
    empresaToSend.imagenPdf = this.imagenSubida;

    this.reqEditarEmpresa(empresaToSend);
  }

  // dinamica de la imagenPdf -----------------------------------------------------------------------

  onSelectImg(event) {
    let fileReader = new FileReader();

    fileReader.readAsDataURL(event['currentFiles'][0]);

    fileReader.onload = () => {
      this.imagenPdf.setValue(fileReader.result);
      this.imagenSubida = fileReader.result;
    };
  }

  uploadAndResize() {
    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation }: UploadResponse) => {
        console.warn(
          'el tamano era: ',
          this.imageCompress.byteCount(image),
          ' bytes'
        );
        console.warn(
          'Comprimiendo and redimencionando a un tamano de 244x244px...'
        );

        this.imageCompress
          .compressFile(image, orientation, 50, 50, 244, 244)
          .then((result: DataUrl) => {
            this.imagenSubida = result;
            console.warn(
              'El tamano ahora es:',
              this.imageCompress.byteCount(result),
              ' bytes'
            );
          });
      });
  }

  onRemoveImg() {
    console.log('esto pasa');
    this.imagenPdf.setValue(null);
    this.imagenSubida = null;
  }

  // llamadas al back -----------------------------------------------------------------------------

  // carga el empresa a editar
  cargarEmpresaAEditar() {
    this.subs.push(
      this.empresaService.getEmpresa().subscribe({
        next: (response) => {
          console.log(response);
          this.loading = false;
          this.empresaAEditar = response;
          this.nombre.setValue(this.empresaAEditar.nombre);
          this.NIF.setValue(this.empresaAEditar.NIF);
          this.direccion.setValue(this.empresaAEditar.direccion);
          this.telefono.setValue(this.empresaAEditar.telefono);
          this.correo.setValue(this.empresaAEditar.correo);
          this.piePagina.setValue(this.empresaAEditar.piePagina);
          this.imagenPdf.setValue(this.empresaAEditar.imagenPdf);

          this.imagenSubida = this.empresaAEditar.imagenPdf;

          this.imagenFile.push(this.imagenSubida);

          console.log(this.newEmpresa);
        },
        error: (error) => {
          console.error(error);
        }
      })
    );
  }

  reqEditarEmpresa(empresaToSend: Empresa) {
    this.loading = true;
    this.subs.push(
      this.empresaService.editEmpresa(empresaToSend).subscribe(
        (response) => {
          console.log(response);
          this.messageService.add({
            severity: 'success',
            summary: 'Exito!',
            detail: 'Empresa editado con exito!',
          });
          console.log(response);
          this.ngOnInit();
        },
        (error) => {
          console.error(error);
          this.loading = false;
          this.messageService.error(error.error.detail);
          return;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((subscripcion) => {
      subscripcion.unsubscribe();
    });
  }
}
