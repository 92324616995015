import { OnInit, inject } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from '../../service/app.layout.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService) {}

  private readonly router = inject(Router)

  usuario: any = JSON.parse(localStorage.getItem('usuario'));

  url: string = null;

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      //  console.log('The URL changed to: ' + event['url'])
       this.url = event['url'];
    });
    // console.log(this.usuario);
    this.model = [
      {
        label: `Bienvenido ${this.usuario.nombre} ${this.usuario.apellido1}`,
        items: [
          {
            label: 'Inicio',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/'],
          },
          {
            label: 'Potenciales Clientes',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/potenciales-clientes'],
          },
          {
            label: 'Acciones Comerciales',
            icon: 'pi pi-fw pi-dollar',
            routerLink: ['/acciones-comerciales'],
          },
          {
            label: 'Tipos Acciones Comerciales',
            icon: 'pi pi-fw pi-bars',
            routerLink: ['/tipos-acciones-comerciales'],
          },
          {
            label: 'Acciones',
            icon: 'pi pi-fw pi-ticket',
            routerLink: ['/acciones'],
          },
          // {
          //   label: `Administracion`,
          //   icon: 'pi pi-user',
          //   items: [
          //     {
          //       label: 'Clientes',
          //       icon: 'pi pi-fw pi-users',
          //       routerLink: [`/clientes`],
          //     },
          //   ],
          // },
        ],
      },
    ];
  }
}
