<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <span>CRM</span>
  </a>

  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>
  <!-- <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="onConfigButtonClick()">
        <i class="pi pi-cog"></i>
    </button> -->

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
  >
    <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button> -->

    <p-avatar
      class="flex align-items-center"
      [image]="userImage"
      shape="circle"
      *ngIf="userImage"
    ></p-avatar>
    <p-avatar
      class="flex align-items-center"
      shape="circle"
      icon="pi pi-user"
      *ngIf="!userImage"
    ></p-avatar>

    <button class="p-link layout-topbar-button" (click)="onConfigButtonClick()">
      <i class="pi pi-cog"></i>
      <span>Configuracion</span>
    </button>

    <button class="p-link layout-topbar-button" [routerLink]="['/auth/login']">
      <i class="pi pi-external-link"></i>
      <span>Salir</span>
    </button>
    <!-- <button class="p-link layout-topbar-button" [routerLink]="'/documentation'">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </button> -->
  </div>
</div>
